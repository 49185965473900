import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withLocalize } from "react-localize-redux";

import { BasePureComponent } from "common/components/Base";
import Toggle from "common/components/Form/components/Toggle";
import Generic from "common/components/Form/components/Generic";
import i18n from "./i18n.json";

/* Volunteer rights. */
class Rights extends BasePureComponent {
  constructor(props) {
    // parent
    super(props);

    // load translations
    props.addTranslation(i18n);

    // apply overrides
    if (props.translations) {
      props.addTranslation(props.translations);
    }
  }

  render() {
    // parent
    super.render();

    // render
    return (
      <div>
        <div className="form-row form-group">
          {/* Harambee? */}
          <Generic>
            {/* can text? */}
            <Toggle
              unwrapped
              name="isHarambeeReader"
              label={this.props.translate(
                "rightsForm.field.isHarambeeReader.label"
              )}
              tooltip={this.props.translate(
                "rightsForm.field.isHarambeeReader.title"
              )}
              disabled={this.props.readOnly || this.props.submitting}
            />
          </Generic>
        </div>
      </div>
    );
  }
}

// turn this into a container component
Rights = withLocalize(connect()(Rights));

// set prop types and required-ness
Rights.propTypes = {
  form: PropTypes.string.isRequired,
  admin: PropTypes.bool,
  readOnly: PropTypes.bool,
  submitting: PropTypes.bool,
  isRestricted: PropTypes.bool,
  translations: PropTypes.object,
};

// set default props
Rights.defaultProps = {
  admin: false,
  readOnly: false,
  submitting: false,
  isRestricted: false,
};

export default Rights;
