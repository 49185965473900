import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withLocalize } from "react-localize-redux";

import { BasePureComponent } from "common/components/Base";
import Checkbox from "common/components/Form/components/Checkbox";
import Generic from "common/components/Form/components/Generic";
import i18n from "./i18n.json";
import "./styles.scss";

/* Site affiliation information. */
class SiteAffiliations extends BasePureComponent {
  constructor(props) {
    // parent
    super(props);

    // load translations
    props.addTranslation(i18n);

    // apply overrides
    if (props.translations) {
      props.addTranslation(props.translations);
    }
  }

  render() {
    // parent
    super.render();

    // renders the sites
    const renderSites = (props, sites) => {
      return sites.map(function (sites, i) {
        return (
          <div key={sites.id} className="fsp-enroll-sites">
            <Checkbox
              unwrapped
              label={sites.name}
              name={`siteAffiliations.${sites.id}`}
              tooltip={props.translate(
                `siteAffiliationsForm.field.siteAffiliations.siteAffiliation.title`
              )}
              disabled={props.readOnly || props.submitting}
              translate={props.translate}
            />
          </div>
        );
      });
    };

    // render
    return (
      <div>
        <div className="form-row form-group">
          {/* siteAffiliations */}
          <Generic
            label={this.props.translate(
              "siteAffiliationsForm.field.siteAffiliations.label"
            )}
          >
            <div className="row no-gutters p-0">
              <div className="col-md-6 p-0">
                {renderSites(
                  this.props,
                  this.props.sites.slice(
                    0,
                    Math.ceil(this.props.sites.length / 2)
                  )
                )}
              </div>
              <div className="col-md-6 p-0">
                {renderSites(
                  this.props,
                  this.props.sites.slice(Math.ceil(this.props.sites.length / 2))
                )}
              </div>
            </div>
          </Generic>
        </div>
      </div>
    );
  }
}

// turn this into a container component
SiteAffiliations = withLocalize(connect()(SiteAffiliations));

// set prop types and required-ness
SiteAffiliations.propTypes = {
  form: PropTypes.string.isRequired,
  admin: PropTypes.bool,
  sites: PropTypes.array.isRequired,
  readOnly: PropTypes.bool,
  submitting: PropTypes.bool,
  isRestricted: PropTypes.bool,
  translations: PropTypes.object,
};

// set default props
SiteAffiliations.defaultProps = {
  admin: false,
  readOnly: false,
  submitting: false,
  isRestricted: false,
};

export default SiteAffiliations;
