import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { formValueSelector, change } from "redux-form";
import { Translate, withLocalize } from "react-localize-redux";
import SignaturePad from "react-signature-pad-wrapper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment/moment";

import { BasePureComponent } from "common/components/Base";
import Button from "common/components/Form/components/Button";
import MessageBox from "common/components/MessageBox";
import Hidden from "common/components/Form/components/Hidden";
import Content from "common/components/Content";
import { hasPermission } from "common/util/session";
import { addAlert } from "common/components/Alerts/actions";
import { showBusy, hideBusy } from "common/components/Busy/actions";
import { setFieldError, clearFieldError } from "common/components/Form/utility";
import { currentDate } from "common/util";
import pen from "./images/pen.png";
import i18n from "./i18n.json";
import "./styles.scss";

/* Consent form. */
class Consent extends BasePureComponent {
  constructor(props) {
    // parent
    super(props);

    // load translations
    props.addTranslation(i18n);

    // apply overrides
    if (props.translations) {
      props.addTranslation(props.translations);
    }

    // the signature pad
    this.signaturePad = null;

    // for text management
    this.state = {
      ...this.state,
      textLoaded: null,
    };

    // if we have no signature or if we're not read only or
    // in admin mode, initialize the fields; this has the effect
    // of always requiring a signature, even if the user has
    // left the page and returned
    if (
      !props.childProtectionPolicySignature ||
      (!props.readOnly && !props.admin)
    ) {
      props.initialize();
    }
  }

  render() {
    // parent
    super.render();

    // helper to flag signature as required
    const signatureRequired = () => {
      setFieldError(
        this.props.form,
        "childProtectionPolicySignature",
        this.props.translate(
          "consentForm.field.childProtectionPolicySignature.error.required"
        )
      );
    };

    // we are always read-only if in admin mode
    const readOnly = this.props.admin || this.props.readOnly;

    // require a signature
    if (!readOnly) {
      signatureRequired();
    }

    // minor?
    const minor =
      this.props.dateOfBirth &&
      currentDate().diff(moment(this.props.dateOfBirth), "years") < 18;

    // render
    return (
      <div>
        {/* if not an admin and not read-only, show the form */}
        {!this.props.admin && !readOnly && (
          <Content
            name="web.childProtectionPolicy"
            errorText={this.props.translate("consentForm.errorLoading")}
            onSuccess={() => {
              this.setState({ textLoaded: true });
            }}
            onError={() => {
              this.setState({ textLoaded: false });
            }}
          />
        )}

        {/* signature pad: do not show if we aren't read-only and failed to load the text */}
        {(readOnly || this.state.textLoaded) && (
          <div>
            {/* minor? */}
            {!readOnly && minor && (
              <div className="row">
                <div className="col">
                  <MessageBox flavor="warning" className="mt-2">
                    <Translate id="consentForm.underAge" />
                  </MessageBox>
                </div>
              </div>
            )}

            <div className="form-row">
              <div className="col">
                <h4>
                  <Translate id="consentForm.field.childProtectionPolicySignature.label" />
                </h4>
              </div>
            </div>
            <div className="form-row">
              {/* if read-only, show the signature image */}
              {readOnly && (
                <div className="col text-center">
                  {this.props.childProtectionPolicySignature ? (
                    <img
                      className="fsp-enroll-signature"
                      src={this.props.childProtectionPolicySignature}
                      alt={this.props.translate(
                        "consentForm.field.childProtectionPolicySignature.title"
                      )}
                    />
                  ) : (
                    <div>
                      {/* no signature on file */}
                      <MessageBox flavor="info">
                        <Translate id="consentForm.noElectronicSignature" />{" "}
                        {this.props.childProtectionPolicy ? (
                          <Translate id="consentForm.paperSignature" />
                        ) : (
                          <Translate id="consentForm.noPaperSignature" />
                        )}
                      </MessageBox>

                      {this.props.admin &&
                        this.props.signChildProtectionPolicy &&
                        this.props.readOnly &&
                        !this.props.childProtectionPolicy &&
                        this.props.volunteerId &&
                        hasPermission("manageVolunteers") && (
                          <Button
                            onClick={() =>
                              this.props
                                .signCPP(this.props.volunteerId)
                                .then((volunteer) => {
                                  // if we have a callback, fire it
                                  if (this.props.onVolunteerUpdate) {
                                    this.props.onVolunteerUpdate(volunteer);
                                  }
                                })
                            }
                            title={this.props.translate(
                              "consentForm.button.signCPP.title"
                            )}
                            alternate={<FontAwesomeIcon icon="upload" />}
                          >
                            <Translate id="consentForm.button.signCPP.label" />
                          </Button>
                        )}
                    </div>
                  )}
                </div>
              )}

              {/* if not read-only, show the signature pad */}
              {!readOnly && (
                <div className="col">
                  <FontAwesomeIcon
                    icon="times"
                    className="fsp-enroll-signature-clear"
                    onClick={() => {
                      // we require a signature
                      signatureRequired();

                      // clear the pad
                      this.signaturePad.clear();

                      // clear the form
                      this.props.saveSignature(null);
                    }}
                  />
                  <div
                    title={this.props.translate(
                      "consentForm.field.childProtectionPolicySignature.title"
                    )}
                    style={{
                      cursor: `url(${pen}) 0 32, auto`,
                    }}
                  >
                    <SignaturePad
                      ref={(signaturePad) => {
                        // capture a reference
                        this.signaturePad = signaturePad;
                      }}
                      redrawOnResize
                      height={200}
                      options={{
                        backgroundColor: "rgb(240,240,240)",
                        onEnd: (data) => {
                          // if we have a signature...
                          if (data && data.target && data.target.toDataURL) {
                            // clear validation errors
                            clearFieldError(
                              this.props.form,
                              "childProtectionPolicySignature"
                            );

                            // save the signature
                            this.props.saveSignature(
                              data.target.toDataURL("image/png")
                            );
                          } else {
                            // we require a signature
                            signatureRequired();
                          }
                        },
                      }}
                    />
                  </div>
                  <Hidden
                    name="childProtectionPolicySignature"
                    disabled={readOnly || this.props.submitting}
                  />
                  <Hidden
                    name="signedChildProtectionPolicyDate"
                    disabled={readOnly || this.props.submitting}
                  />
                </div>
              )}
            </div>

            {(this.props.childProtectionPolicySignature ||
              !this.props.admin) && (
              <div className="form-row fsp-enroll-details">
                <div className="d-block d-sm-none col-sm-6 text-right">
                  {!minor && this.props.firstName && (
                    <span>{this.props.firstName}</span>
                  )}{" "}
                  {!minor && this.props.lastName && (
                    <span>{this.props.lastName}</span>
                  )}
                </div>
                <div className="d-none d-sm-inline-block col-sm-6">
                  {!minor && this.props.firstName && (
                    <span>{this.props.firstName}</span>
                  )}{" "}
                  {!minor && this.props.lastName && (
                    <span>{this.props.lastName}</span>
                  )}
                </div>
                <div className="col-sm-6 text-right">
                  {currentDate().format("LL")}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

// map state to properties relevant to this component
const mapStateToProps = (state, ownProps) => ({
  // first name
  firstName: formValueSelector(ownProps.form)(state, "firstName"),

  // last name
  lastName: formValueSelector(ownProps.form)(state, "lastName"),

  // date of birth
  dateOfBirth: formValueSelector(ownProps.form)(state, "dateOfBirth"),

  // volunteer ID
  volunteerId: formValueSelector(ownProps.form)(state, "id"),

  // CPP flag
  childProtectionPolicy: formValueSelector(ownProps.form)(
    state,
    "childProtectionPolicy"
  ),

  // the signature, encoded
  childProtectionPolicySignature: formValueSelector(ownProps.form)(
    state,
    "childProtectionPolicySignature"
  ),
});

// map dispatch function to callback props so that the component can invoke them
const mapDispatchToProps = (dispatch, ownProps) => ({
  // initialize the form
  initialize: () => {
    dispatch(change(ownProps.form, "childProtectionPolicySignature", null));
    dispatch(change(ownProps.form, "signedChildProtectionPolicyDate", null));
  },

  // flags the CPP as signed
  signCPP: (volunteerId) => {
    if (ownProps.signChildProtectionPolicy) {
      // show the busy indicator
      const busyId = dispatch(showBusy());

      // do the delete
      return dispatch(ownProps.signChildProtectionPolicy(volunteerId))
        .catch((e) => {
          console.error("Error marking policy as signed", e);
          dispatch(
            addAlert("error", ownProps.translate("consentForm.error.signCPP"))
          );
        })
        .finally(() => {
          // hide the busy indicator
          dispatch(hideBusy(busyId));
        });
    }
  },

  // save signature
  saveSignature: (data) => {
    // save it on the form
    dispatch(change(ownProps.form, "childProtectionPolicySignature", data));
    dispatch(
      change(
        ownProps.form,
        "signedChildProtectionPolicyDate",
        data ? currentDate().format("YYYY-MM-DD") : null
      )
    );
  },
});

// turn this into a container component
Consent = withLocalize(connect(mapStateToProps, mapDispatchToProps)(Consent));

// set prop types and required-ness
Consent.propTypes = {
  form: PropTypes.string.isRequired,
  admin: PropTypes.bool,
  readOnly: PropTypes.bool,
  submitting: PropTypes.bool,
  signChildProtectionPolicy: PropTypes.func,
  onVolunteerUpdate: PropTypes.func,
  translations: PropTypes.object,
};

// set default props
Consent.defaultProps = {
  admin: false,
  readOnly: false,
  submitting: false,
};

export default Consent;
