import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { formValueSelector, change } from "redux-form";
import { Translate, withLocalize } from "react-localize-redux";
import Script from "react-load-script";

import { BasePureComponent } from "common/components/Base";
import ElementGroup from "common/components/ElementGroup";
import Text from "common/components/Form/components/Text";
import SelectState from "common/components/Form/components/SelectState";
import Button from "common/components/Form/components/Button";
import { formatZip } from "common/util/formatters";
import { addressesMatch, validateAddress } from "common/util";
import i18n from "./i18n.json";

/* Address information. */
class Address extends BasePureComponent {
  constructor(props) {
    // parent
    super(props);

    // load translations
    props.addTranslation(i18n);

    // apply overrides
    if (props.translations) {
      props.addTranslation(props.translations);
    }

    // for geocoding
    this.state = {
      ...this.state,
      mapsLoaded: false,
      address: null,
      keepAddress: null,
    };
  }

  validateAddress = (props) => {
    // no need to do this if read-only
    if (props.readOnly) {
      return;
    }

    // only proceed if the API is loaded
    if (!this.state.mapsLoaded) {
      return;
    }

    // validate it
    const state = this.state;
    validateAddress(props)
      .then((address) => {
        // make sure we're still alive and not read-only
        if (super.isMounted() && !props.readOnly) {
          if (address) {
            // put it on the state, but only if it changed
            if (!state.address || !addressesMatch(state.address, address)) {
              this.setState({
                keepAddress: null,
                address: address,
              });
            }
          } else {
            // clear address on the state
            this.setState({
              address: null,
            });
          }
        }
      })
      .catch((e) => {
        // log it
        console.error("Error validating address", e);

        // clear address on the state
        if (super.isMounted()) {
          this.setState({
            address: null,
          });
        }
      });
  };

  render() {
    // parent
    super.render();

    // render
    return (
      <div>
        <div className="form-row form-group">
          {/* load Google maps for geocoding */}
          <Script
            url={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GMAPS_KEY}`}
            onLoad={() => {
              this.setState({ mapsLoaded: true }, true);
            }}
            onError={(e) => {
              console.error("Error loading Google Maps API", e);
            }}
          />

          {/* street1 */}
          <Text
            colspan={9}
            label={this.props.translate(
              "addressForm.field.address.street1.label"
            )}
            name="address.street1"
            autoComplete={!this.props.admin ? "address-line1" : null}
            placeholder={this.props.translate(
              "addressForm.field.address.street1.label"
            )}
            tooltip={this.props.translate(
              "addressForm.field.address.street1.title"
            )}
            maxLength={64}
            required={this.props.partialAddress}
            disabled={this.props.readOnly || this.props.submitting}
            onBlur={
              !this.state.keepAddress
                ? () => this.validateAddress(this.props)
                : null
            }
          />

          {/* street2 */}
          <Text
            colspan={3}
            label={this.props.translate(
              "addressForm.field.address.street2.label"
            )}
            name="address.street2"
            autoComplete={!this.props.admin ? "address-line2" : null}
            placeholder={this.props.translate(
              "addressForm.field.address.street2.label"
            )}
            tooltip={this.props.translate(
              "addressForm.field.address.street2.title"
            )}
            maxLength={64}
            disabled={this.props.readOnly || this.props.submitting}
            onBlur={
              !this.state.keepAddress
                ? () => this.validateAddress(this.props)
                : null
            }
          />
        </div>

        <div className="form-row form-group">
          {/* city */}
          <Text
            colspan={5}
            label={this.props.translate("addressForm.field.address.city.label")}
            name="address.city"
            autoComplete={!this.props.admin ? "address-level2" : null}
            placeholder={this.props.translate(
              "addressForm.field.address.city.label"
            )}
            tooltip={this.props.translate(
              "addressForm.field.address.city.title"
            )}
            maxLength={32}
            autoCapitalize
            required={this.props.partialAddress}
            disabled={this.props.readOnly || this.props.submitting}
            onBlur={
              !this.state.keepAddress
                ? () => this.validateAddress(this.props)
                : null
            }
          />

          {/* state */}
          <SelectState
            colspan={4}
            label={this.props.translate(
              "addressForm.field.address.state.label"
            )}
            name="address.state"
            autoComplete={!this.props.admin ? "address-level1" : null}
            placeholder={this.props.translate(
              "addressForm.field.address.state.label"
            )}
            tooltip={this.props.translate(
              "addressForm.field.address.state.title"
            )}
            required={this.props.partialAddress}
            disabled={this.props.readOnly || this.props.submitting}
            whitelist={["NC", "SC"]}
            translate={this.props.translate}
            onBlur={
              !this.state.keepAddress
                ? () => this.validateAddress(this.props)
                : null
            }
          />

          {/* zip */}
          <Text
            colspan={3}
            label={this.props.translate("addressForm.field.address.zip.label")}
            name="address.zip"
            autoComplete={!this.props.admin ? "postal-code" : null}
            placeholder={this.props.translate(
              "addressForm.field.address.zip.label"
            )}
            tooltip={this.props.translate(
              "addressForm.field.address.zip.title"
            )}
            format={formatZip}
            minLength={5}
            maxLength={10}
            pattern="[0-9]{5}(-[0-9]{4})?"
            required={this.props.partialAddress}
            disabled={this.props.readOnly || this.props.submitting}
            onBlur={
              !this.state.keepAddress
                ? () => this.validateAddress(this.props)
                : null
            }
          />
        </div>

        {/* address validation */}
        {!this.props.readOnly &&
          this.state.address &&
          !this.state.keepAddress &&
          !addressesMatch(this.props, this.state.address, false) && (
            <div>
              <div className="form-row form-group">
                <div className="col">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col" style={{ fontWeight: "bold" }}>
                        <label className="col-form-label">
                          <Translate id="addressForm.addressNew" />
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col mb-2" style={{ fontSize: "larger" }}>
                        {this.state.address.street1}
                        {this.state.address.street2 && (
                          <span>
                            {", "}
                            <Translate id="addressForm.field.address.street2.label" />{" "}
                            {this.props.street2}
                          </span>
                        )}
                        <br />
                        {this.state.address.city}
                        {", "}
                        {this.state.address.state} {this.state.address.zip}
                        <br />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <ElementGroup>
                          <Button
                            onClick={() => {
                              this.setState({ keepAddress: true });
                            }}
                            size={"sm"}
                            title={this.props.translate(
                              "addressForm.button.useOld.title"
                            )}
                          >
                            <Translate id="addressForm.button.useOld.label" />
                          </Button>
                          <Button
                            onClick={() => {
                              // update the form
                              this.props.updateAddress(this.state.address);

                              // clear the state
                              this.setState({ address: null });
                            }}
                            size={"sm"}
                            title={this.props.translate(
                              "addressForm.button.useNew.title"
                            )}
                          >
                            <Translate id="addressForm.button.useNew.label" />
                          </Button>
                        </ElementGroup>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
    );
  }
}

// map state to properties relevant to this component
const mapStateToProps = (state, ownProps) => ({
  // the address parts, for address validation
  street1: formValueSelector(ownProps.form)(state, "address.street1"),
  street2: formValueSelector(ownProps.form)(state, "address.street2"),
  city: formValueSelector(ownProps.form)(state, "address.city"),
  state: formValueSelector(ownProps.form)(state, "address.state"),
  zip: formValueSelector(ownProps.form)(state, "address.zip"),

  // do we have a partial address?
  partialAddress:
    formValueSelector(ownProps.form)(state, "address.street1") ||
    formValueSelector(ownProps.form)(state, "address.city") ||
    formValueSelector(ownProps.form)(state, "address.state") ||
    formValueSelector(ownProps.form)(state, "address.zip")
      ? true
      : false,
});

// map dispatch function to callback props so that the component can invoke them
const mapDispatchToProps = (dispatch, ownProps) => ({
  // set the country
  setCountry: (country) => {
    return dispatch(change(ownProps.form, "address.country", country));
  },

  // update the address on the form
  updateAddress: (address) => {
    if (address) {
      if (address.street1) {
        dispatch(change(ownProps.form, "address.street1", address.street1));
      }
      if (address.street2) {
        dispatch(change(ownProps.form, "address.street2", address.street2));
      }
      if (address.city) {
        dispatch(change(ownProps.form, "address.city", address.city));
      }
      if (address.state) {
        dispatch(change(ownProps.form, "address.state", address.state));
      }
      if (address.zip) {
        dispatch(change(ownProps.form, "address.zip", address.zip));
      }
    }
  },
});

// turn this into a container component
Address = withLocalize(connect(mapStateToProps, mapDispatchToProps)(Address));

// set prop types and required-ness
Address.propTypes = {
  form: PropTypes.string.isRequired,
  admin: PropTypes.bool,
  readOnly: PropTypes.bool,
  submitting: PropTypes.bool,
  translations: PropTypes.object,
};

// set default props
Address.defaultProps = {
  admin: false,
  readOnly: false,
  submitting: false,
};

export default Address;
