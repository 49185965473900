import { SubmissionError } from "redux-form";
import { object as dotObject } from "dot-object";

import { BasePureComponent } from "common/components/Base";
import { setFieldError } from "./utility";
import { scrollToTop } from "common/util";
import * as errors from "common/util/errors";
import "./styles.scss";
if (window.navigator.platform.match("Mac") !== null) {
  require("./styles-mac.scss");
}

/**
 * A base form which all other forms should extend.
 */
export class BaseForm extends BasePureComponent {
  // nothing right now
}

/**
 * A base edit form which all other edit forms should extend.
 */
export class BaseEditForm extends BasePureComponent {
  constructor(props) {
    // parent
    super(props);

    // for edit management
    this.state = {
      ...this.state,
      currentlyEditing: props.addition ? "NEW" : null,
    };

    // make 'this' available in methods
    this.submit = this.submit.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    // if an addition, note that
    if (props.addition) {
      // new object
      return { currentlyEditing: "NEW" };
    } else if (
      // if the entity doesn't have an ID, check for a name
      props.entity &&
      ((props.entity.id && props.entity.id !== state.currentlyEditing) ||
        (!props.entity.id &&
          props.entity.name &&
          props.entity.name !== state.currentlyEditing))
    ) {
      // the object changed; reset the edit state
      return { currentlyEditing: null };
    } else {
      return null;
    }
  }

  // clears tooltips and then invokes the onSubmit() callback passed in via props
  submit(values, translate, dispatch, scrollToTopOnError = true) {
    // make the call to save
    return this.props
      .onSubmit(values)
      .then(() => {
        // no longer editing
        this.setState({ currentlyEditing: null });
      })
      .then((result) => {
        // disable editing
        if (super.isMounted()) {
          this.setState({ currentlyEditing: null });
        }

        // propagate the result
        return result;
      })
      .catch((e) => {
        // log it
        console.error("Error submitting form", e);

        // propagate it so that the fail handler runs
        processErrors(e, translate, dispatch, null, null, scrollToTopOnError);
      });
  }
}

/**
 * Processes errors for UI display.
 *
 * This logic tightly integrates with redux-form's error display logic. If there are
 * errors, the result of this call is a SubmissionError thrown containing those errors.
 */
export function processErrors(
  errs,
  translate,
  dispatch = null,
  genericMessage = null,
  handler = null,
  scrollToTopOnError = true
) {
  // special case; this can happen when we intercept a 401
  if (!errs) {
    return;
  }

  // log it
  console.debug("Processing errors for display", errs);

  // we deal with arrays
  if (!Array.isArray(errs)) {
    errs = [errs];
  }

  // to keep track of generic errors
  let genericError = false;

  // process all errors
  let processedErrors = {};
  errs.forEach(function (error) {
    // if we have a handler defined, let that take a crack at it first
    let processed;
    if (handler) {
      processed = handler(error, dispatch);
      if (processed) {
        processedErrors = {
          ...processedErrors,
          ...dotObject(processed),
        };
      }
    }

    // if the processed error indicates that it is a field error
    // but does not contain a field, use the original (if there is one)
    if (processed && processed.fieldError && !processed.field) {
      processed.field = error.field;
    }

    // if we processed it into a new error, use that one
    error = processed ? processed : error;

    // is it a field specific error?
    if (error.field) {
      // flag it as a field error
      processedErrors = {
        ...processedErrors,
        fieldError: true,
      };

      // attempt to flip the HTML5 validity for that field,
      // but don't abort if we can't do so
      setFieldError(null, error.field, translate("error.form.fieldInvalid"));

      // if not yet processed, do so; we drive off of the code
      if (!processed) {
        switch (error.code) {
          // we don't know this code
          default:
            processedErrors = {
              ...processedErrors,
              // typically we'd let the UI craft the messages (and localize them), but we were careful
              // to write customer-friendly messages in the Hub, so we'll use them directly
              ...dotObject({
                [error.field]: error.message,
              }),

              // we actually have to add it twice; the above notation takes care of the field-level
              // message, and the following notation takes care of highlighting the invalid field
              [error.field]: error.message,
            };
            break;
        }
      }

      // add a generic message too
      if (!genericError) {
        processedErrors = {
          ...processedErrors,
          _error: translate(
            errs.length !== 1
              ? "error.form.fieldErrors"
              : "error.form.fieldError"
          ),
        };
        genericError = true;
      }
    } else {
      // if not yet processed, do so
      if (!processed) {
        // looks like it's a non-field error; we still drive off of the code
        switch (error.code) {
          // these are swagger error codes that indicate a request payload problem
          case 415:
          case 601:
          case 608:
            // note the error
            if (!genericError) {
              processedErrors = {
                ...processedErrors,
                _error:
                  translate("error.form.invalidPayload") +
                  " " +
                  translate("error.form.sorry"),
              };
              genericError = true;
            }
            break;

          // this is a swagger error code; let's try to figure out which field is in error
          case 602:
          case 603:
          case 604:
            // required
            if (
              error.message &&
              (error.message.endsWith(" in body is required") ||
                error.message.endsWith(" in query is required"))
            ) {
              // extract the field and build a new error using it
              const field = error.message
                .replace(" in body is required", "")
                .replace(" in query is required", "");
              processedErrors = {
                ...processedErrors,
                ...dotObject({
                  [field]: translate("error.form.fieldRequired"),
                }),
              };

              // flag it as a field error
              processedErrors = {
                ...processedErrors,
                fieldError: true,
              };

              // add a generic message too
              if (!genericError) {
                processedErrors = {
                  ...processedErrors,
                  _error: translate(
                    errs.length !== 1
                      ? "error.form.fieldErrors"
                      : "error.form.fieldError"
                  ),
                };
                genericError = true;
              }

              // too long
            } else if (
              error.message &&
              error.message.includes(" in body should be at most") &&
              error.message.includes(" chars long")
            ) {
              // extract the field
              const field = error.message.substring(
                0,
                error.message.indexOf(" in body should be at most")
              );

              // extract the max size
              const maxSize = error.message.substring(
                error.message.indexOf(" in body should be at most") +
                  " in body should be at most".length +
                  1,
                error.message.indexOf(" chars long")
              );

              // build a new error using it
              processedErrors = {
                ...processedErrors,
                ...dotObject({
                  [field]: translate("error.form.fieldTooLong", {
                    max: maxSize,
                  }),
                }),
              };

              // flag it as a field error
              processedErrors = {
                ...processedErrors,
                fieldError: true,
              };

              // add a generic message too
              if (!genericError) {
                processedErrors = {
                  ...processedErrors,
                  _error: translate(
                    errs.length !== 1
                      ? "error.form.fieldErrors"
                      : "error.form.fieldError"
                  ),
                };
                genericError = true;
              }

              // too short
            } else if (
              error.message &&
              error.message.includes(" in body should be at least") &&
              error.message.includes(" chars long")
            ) {
              // extract the field
              const field = error.message.substring(
                0,
                error.message.indexOf(" in body should be at least")
              );

              // extract the min size
              const minSize = error.message.substring(
                error.message.indexOf(" in body should be at least") +
                  " in body should be at least".length +
                  1,
                error.message.indexOf(" chars long")
              );

              // build a new error using it
              processedErrors = {
                ...processedErrors,
                ...dotObject({
                  [field]: translate("error.form.fieldTooShort", {
                    min: minSize,
                  }),
                }),
              };

              // flag it as a field error
              processedErrors = {
                ...processedErrors,
                fieldError: true,
              };

              // add a generic message too
              if (!genericError) {
                processedErrors = {
                  ...processedErrors,
                  _error: translate(
                    errs.length !== 1
                      ? "error.form.fieldErrors"
                      : "error.form.fieldError"
                  ),
                };
                genericError = true;
              }
            } else {
              // we don't recognize it
              if (!genericError) {
                processedErrors = {
                  ...processedErrors,
                  _error:
                    translate("error.form.unrecognizedError") +
                    " " +
                    translate("error.form.sorry"),
                };
                genericError = true;
              }
            }
            break;

          // this is a swagger error code; let's try to figure out which field is in error
          case 605:
            if (
              error.message &&
              error.message.includes(" in body should match")
            ) {
              // extract the field and build a new error using it
              const field = error.message.replace(
                /( in body should match).*/,
                ""
              );
              processedErrors = {
                ...processedErrors,
                ...dotObject({
                  [field]: translate("error.form.fieldInvalid"),
                }),
              };

              // flag it as a field error
              processedErrors = {
                ...processedErrors,
                fieldError: true,
              };

              // add a generic message too
              if (!genericError) {
                processedErrors = {
                  ...processedErrors,
                  _error: translate(
                    errs.length !== 1
                      ? "error.form.fieldErrors"
                      : "error.form.fieldError"
                  ),
                };
                genericError = true;
              }
            } else {
              // we don't recognize it
              if (!genericError) {
                processedErrors = {
                  ...processedErrors,
                  _error:
                    translate("error.form.unrecognizedError") +
                    " " +
                    translate("error.form.sorry"),
                };
                genericError = true;
              }
            }
            break;

          // nothing we can do about this
          case errors.BAD_REQUEST:
            // note the error
            if (!genericError) {
              processedErrors = {
                ...processedErrors,
                _error:
                  translate("error.form.badRequest") +
                  " " +
                  translate("error.form.sorry"),
              };
              genericError = true;
            }
            break;

          // nothing we can do about this
          case errors.FORBIDDEN:
            // note the error
            if (!genericError) {
              processedErrors = {
                ...processedErrors,
                _error:
                  translate("error.form.forbidden") +
                  " " +
                  translate("error.form.sorry"),
              };
              genericError = true;
            }
            break;

          // this is not a business error; it's a legit 404
          case errors.NOT_FOUND:
            // note the error
            if (!genericError) {
              processedErrors = {
                ...processedErrors,
                _error:
                  translate("error.form.notFound") +
                  " " +
                  translate("error.form.sorry"),
              };
              genericError = true;
            }
            break;

          // network errors are handled higher up
          case errors.NETWORK_ERROR:
            break;

          // nothing we can do about this
          case errors.SERVER_ERROR:
            // note the error
            if (!genericError) {
              processedErrors = {
                ...processedErrors,
                _error:
                  translate("error.form.serverError") +
                  " " +
                  translate("error.form.sorry"),
              };
              genericError = true;
            }
            break;

          // challenge verification failure
          case 11000:
            processedErrors = {
              ...processedErrors,
              _error: translate("error.form.reCAPTCHA"),
            };
            genericError = true;
            break;

          // catch-all
          default:
            if (!genericError) {
              processedErrors = {
                ...processedErrors,
                _error: genericMessage
                  ? translate(genericMessage)
                  : error.message
                  ? error.message
                  : translate("error.form.generic"),
              };
              genericError = true;
            }
        }
      }
    }
  });

  // if we have a generic error, potentially scroll to the top of the page
  if (processedErrors._error && scrollToTopOnError) {
    scrollToTop();
  }

  // log and throw
  console.debug("Processed errors for display", processedErrors);
  throw new SubmissionError(processedErrors);
}

/**
 * Initializes an "other" field. For example, if the user selects "Other" for
 * language and types "Klingon", language will be "Klingon" in the DB. We need
 * to recognize that "Klingon" is not one of our recognized languages, set
 * "language" to "other" and "otherLanguage" to "Klingon".
 */
export function initializeOtherField(fieldName, object, validOptions) {
  // sanity check
  if (!object) {
    return;
  }

  // other field name
  const otherFieldName =
    "other" + fieldName.charAt(0).toUpperCase() + fieldName.slice(1);

  // "other" field value
  let otherFieldValue = object[otherFieldName];
  if (otherFieldValue === "") {
    otherFieldValue = null;
  }

  // if it's not set, use the main field's value
  if (!otherFieldValue) {
    otherFieldValue = object[fieldName];
  }

  // if we have an "other" value...
  if (otherFieldValue) {
    // ... see if it matches one of the pre-defined values
    let found = false;
    for (const o in validOptions) {
      if (o !== "other" && o === otherFieldValue) {
        // it matches
        found = true;
        break;
      }
    }

    // did it match?
    if (!found) {
      // no; it must be an 'other'
      return {
        [fieldName]: "other",
        [otherFieldName]: otherFieldValue,
      };
    } else {
      // it matched a pre-defined value; clear the "other" field
      return {
        [fieldName]: otherFieldValue,
        [otherFieldName]: null,
      };
    }
  } else {
    // no changes
    return {};
  }
}
