import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { formValueSelector, change } from "redux-form";
import { withLocalize } from "react-localize-redux";

import { BasePureComponent } from "common/components/Base";
import Text from "common/components/Form/components/Text";
import DateField from "common/components/Form/components/DateTime";
import Select from "common/components/Form/components/Select";
import RadioGroup from "common/components/Form/components/RadioGroup";
import { currentDate } from "common/util";
import i18n from "./i18n.json";

/* Personal information. */
class Personal extends BasePureComponent {
  constructor(props) {
    // parent
    super(props);

    // load translations
    props.addTranslation(i18n);

    // apply overrides
    if (props.translations) {
      props.addTranslation(props.translations);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // parent
    super.componentDidUpdate(prevProps, prevState);

    // if not "other", clear any custom language value
    if (
      this.props.language &&
      "other" !== this.props.language &&
      this.props.otherLanguage &&
      this.props.otherLanguage !== ""
    ) {
      this.props.dispatch(change(this.props.form, "otherLanguage", null));
    }
  }

  render() {
    // parent
    super.render();

    // render
    return (
      <div>
        <div className="form-row form-group">
          {/* firstName */}
          <Text
            colspan={6}
            label={this.props.translate("personalForm.field.firstName.label")}
            name="firstName"
            autoComplete={!this.props.admin ? "given-name" : null}
            placeholder={this.props.translate(
              "personalForm.field.firstName.label"
            )}
            tooltip={this.props.translate("personalForm.field.firstName.title")}
            maxLength={32}
            autoCapitalize
            required
            disabled={
              this.props.isRestricted ||
              this.props.readOnly ||
              this.props.submitting
            }
          />

          {/* lastName */}
          <Text
            colspan={6}
            label={this.props.translate("personalForm.field.lastName.label")}
            name="lastName"
            autoComplete={!this.props.admin ? "family-name" : null}
            placeholder={this.props.translate(
              "personalForm.field.lastName.label"
            )}
            tooltip={this.props.translate("personalForm.field.lastName.title")}
            maxLength={32}
            autoCapitalize
            required
            disabled={
              this.props.isRestricted ||
              this.props.readOnly ||
              this.props.submitting
            }
          />
        </div>

        <div className="form-row form-group">
          {/* gender */}
          <RadioGroup
            colspan={6}
            label={this.props.translate("personalForm.field.gender.label")}
            name="gender"
            tooltip={this.props.translate("personalForm.field.gender.title")}
            required={false}
            disabled={
              this.props.isRestricted ||
              this.props.readOnly ||
              this.props.submitting
            }
            horizontal
            keyPrefix={"personalForm.field.gender.options"}
            options={i18n.personalForm.field.gender.options}
            translate={this.props.translate}
          />

          {/* dateOfBirth */}
          <DateField
            colspan={6}
            label={this.props.translate("personalForm.field.dateOfBirth.label")}
            name="dateOfBirth"
            autoComplete={!this.props.admin ? "bday" : null}
            placeholder={this.props.translate(
              "personalForm.field.dateOfBirth.label"
            )}
            tooltip={this.props.translate(
              "personalForm.field.dateOfBirth.title"
            )}
            minDate={
              currentDate().year() - 100 + currentDate().format("-MM-DD")
            }
            maxDate={currentDate().year() - 16 + currentDate().format("-MM-DD")}
            required
            disabled={
              this.props.isRestricted ||
              this.props.readOnly ||
              this.props.submitting
            }
          />
        </div>

        <div className="form-row form-group">
          {/* language */}
          <Select
            colspan={6}
            label={this.props.translate("personalForm.field.language.label")}
            name="language"
            placeholder={this.props.translate(
              "personalForm.field.language.label"
            )}
            tooltip={this.props.translate("personalForm.field.language.title")}
            required
            disabled={this.props.readOnly || this.props.submitting}
            options={i18n.personalForm.field.language.options}
            keyPrefix="personalForm.field.language.options"
            translate={this.props.translate}
            containerChildren={
              "other" === this.props.language ? (
                <div className="mt-2">
                  <Text
                    unwrapped
                    name="otherLanguage"
                    autoComplete={!this.props.admin ? "language" : null}
                    placeholder={this.props.translate(
                      "personalForm.field.language.label"
                    )}
                    tooltip={this.props.translate(
                      "personalForm.field.language.title"
                    )}
                    maxLength={32}
                    autoCapitalize
                    required={"other" === this.props.language}
                    disabled={
                      this.props.readOnly ||
                      this.props.submitting ||
                      "other" !== this.props.language
                    }
                  />
                </div>
              ) : null
            }
          />
        </div>
      </div>
    );
  }
}

// map state to properties relevant to this component
const mapStateToProps = (state, ownProps) => ({
  // current language
  siteLanguage: window.$localStorage.getItem("language"),

  // the selected language
  language: formValueSelector(ownProps.form)(state, "language"),

  // custom language
  otherLanguage: formValueSelector(ownProps.form)(state, "otherLanguage"),
});

// turn this into a container component
Personal = withLocalize(connect(mapStateToProps)(Personal));

// set prop types and required-ness
Personal.propTypes = {
  form: PropTypes.string.isRequired,
  admin: PropTypes.bool,
  addition: PropTypes.bool,
  readOnly: PropTypes.bool,
  submitting: PropTypes.bool,
  isRestricted: PropTypes.bool,
  translations: PropTypes.object,
};

// set default props
Personal.defaultProps = {
  admin: false,
  addition: false,
  readOnly: false,
  submitting: false,
  isRestricted: false,
};

export default Personal;
