import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Field, FieldArray } from "redux-form";

import { BaseFormComponent } from "..";
import { clearFieldError } from "../../utility";
import { renderField } from "..";
import * as v from "app/variables";

/* A form radio group, along with some of the styling around it. */
class RadioGroup extends BaseFormComponent {
  render() {
    // parent, for lifecycle logging
    super.render();

    // render
    return (
      <div
        className={
          this.props.unwrapped
            ? "has-error"
            : (this.props.colspan && this.props.colspan > 0
                ? `col-${this.props.colbreak ? `${this.props.colbreak}-` : ""}${
                    this.props.colspan
                  }`
                : "col") + " has-error"
        }
      >
        {/* we need to wrap everything in a FieldArray to get access to certain form props */}
        <FieldArray
          size={this.props.size}
          label={this.props.label}
          name={this.props.name + "Array"}
          component={renderRadioGroup}
          optionComponent={this.props.component}
          optionsName={this.props.name}
          options={this.props.options}
          keyPrefix={this.props.keyPrefix}
          required={this.props.required}
          disabled={this.props.disabled}
          onFocus={this.props.onFocus}
          onChange={this.props.onChange}
          onBlur={this.props.onBlur}
          onDragStart={this.props.onDragStart}
          onDrop={this.props.onDrop}
          horizontal={this.props.horizontal}
          translate={this.props.translate}
          // this is a kludge, but redux-form doesn't seem to properly
          // provide FieldArray components with any errors, so we have
          // to do it; even better, we don't know the form name until
          // we get into the renderer, so we just pass ALL form information
          forms={this.props.forms}
        />
        {this.props.containerChildren}
      </div>
    );
  }
}

// renderer
const renderRadioGroup = ({
  meta: { form, touched, error },
  size,
  optionComponent,
  label,
  optionsName,
  options,
  keyPrefix,
  required,
  disabled,
  onFocus,
  onChange,
  onBlur,
  onDragStart,
  onDrop,
  horizontal,
  translate,
  forms,
}) => {
  // on the off chance, we did get an error, don't smash it
  if (!error) {
    // we now know the form name, so we can look for form-specific errors
    if (form && forms && forms[form] && forms[form].submitErrors) {
      // look for a field-specific error
      error = forms[form].submitErrors[optionsName];
    }
  }

  const renderOption = (
    key,
    form,
    name,
    keyPrefix,
    required,
    disabled,
    translate
  ) => {
    return (
      <Field
        type="radio"
        id={`${name}-${key}`}
        name={name}
        className={`form-check-input form-control form-control-${size}`}
        label={translate(keyPrefix + "." + key)}
        value={
          key === "true" || key === "false"
            ? key === "true"
            : isNaN(key)
            ? key
            : Number(key)
        }
        parse={
          key === "true" || key === "false"
            ? (value) => key === "true"
            : isNaN(key)
            ? null
            : (value) => Number(key)
        }
        component={optionComponent}
        title={translate(keyPrefix + "." + key)}
        required={required}
        disabled={disabled}
        onFocus={onFocus}
        onChange={onChange}
        onBlur={onBlur}
        onDragStart={onDragStart}
        onDrop={onDrop}
      />
    );
  };

  let items = [];
  for (let key in options) {
    items.push(
      !horizontal ? (
        <div key={key}>
          <div className="form-check form-check-inline">
            {renderOption(
              key,
              form,
              optionsName,
              keyPrefix,
              required,
              disabled,
              translate
            )}
            <label
              htmlFor={`${optionsName}-${key}`}
              className={`form-check-label form-check-label-${size}`}
            >
              {translate(`${keyPrefix}.${key}`)}
            </label>
          </div>
        </div>
      ) : (
        <div key={key} className="form-check form-check-inline">
          {renderOption(
            key,
            form,
            optionsName,
            keyPrefix,
            required,
            disabled,
            translate
          )}
          <label htmlFor={`${optionsName}-${key}`} className="form-check-label">
            {translate(`${keyPrefix}.${key}`)}
          </label>
        </div>
      )
    );
  }

  // render it all
  return renderField(
    size,
    optionsName,
    label,
    disabled,
    required,
    touched,
    error,
    `col-form-label col-form-label-${size}`,
    null, // tooltips don't work on radio groups
    <div>
      <div>{items}</div>
      {/* field error */}
      {error && (
        <div>
          <span className="fsp-field-error">{error}</span>
        </div>
      )}
    </div>
  );
};

// renderer
const renderRadio = ({
  meta: { form, error },
  input,
  id = null,
  type,
  className = "",
  tooltip = null,
  required = false,
  disabled = false,
}) => {
  return (
    <input
      {...input}
      id={id ? id : input.name}
      disabled={disabled}
      required={required}
      type={type}
      className={className}
      data-html="true"
      title={tooltip}
      onFocus={input.onFocus}
      onChange={(e) => {
        // clear HTML5 errors; this is the only place we
        // can clear field-specific server errors
        clearFieldError(form, input.name);

        // if we have a callback, invoke it
        if (input.onChange) {
          input.onChange(e);
        }
      }}
      onBlur={input.onBlur}
      onDragStart={input.onDragStart}
      onDrop={input.onDrop}
    />
  );
};

// map state to properties relevant to this component
const mapStateToProps = (state, ownProps) => ({
  // kludge alert: we need to find errors for this field, but we
  // don't know the form name yet, so we need to get ALL forms
  forms: state.form,
});

// turn this into a container component
RadioGroup = connect(mapStateToProps)(RadioGroup);

// set prop types and required-ness
RadioGroup.propTypes = {
  unwrapped: PropTypes.bool,
  colbreak: PropTypes.string,
  colspan: PropTypes.number,
  size: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  name: PropTypes.string,
  tooltip: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  horizontal: PropTypes.bool,
  validate: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onDragStart: PropTypes.func,
  onDrop: PropTypes.func,
  options: PropTypes.object.isRequired,
  keyPrefix: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
  containerChildren: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
};

// set default props
RadioGroup.defaultProps = {
  unwrapped: false,
  colbreak: "sm",
  size: v.formWidgetSize,
  component: renderRadio,
  required: false,
  disabled: false,
  horizontal: false,
};

export default RadioGroup;
