import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";

import { BaseFormComponent } from "..";
import { clearFieldError } from "../../utility";
import * as v from "app/variables";

/* A form checkbox field, along with some of the styling around it. */
class Checkbox extends BaseFormComponent {
  render() {
    // parent, for lifecycle logging
    super.render();

    // render
    return (
      <div
        className={
          this.props.unwrapped
            ? "has-error"
            : (this.props.colspan && this.props.colspan > 0
                ? `col-${this.props.colbreak ? `${this.props.colbreak}-` : ""}${
                    this.props.colspan
                  }`
                : "col") + " has-error"
        }
      >
        <Field
          type="checkbox"
          size={this.props.size}
          label={this.props.label}
          name={this.props.name}
          labelClassName={
            !this.props.unwrapped
              ? `col-form-label col-form-label-${this.props.size}`
              : null
          }
          className={`form-control form-control-${this.props.size}`}
          component={this.props.component}
          tooltip={this.props.tooltip}
          required={this.props.required}
          disabled={this.props.disabled}
          validate={this.props.validate}
          onFocus={this.props.onFocus}
          onChange={this.props.onChange}
          onBlur={this.props.onBlur}
          onDragStart={this.props.onDragStart}
          onDrop={this.props.onDrop}
        />
        {this.props.containerChildren}
      </div>
    );
  }
}

// renderer
const renderCheckbox = ({
  meta: { form, touched, error },
  size,
  input,
  id = null,
  type,
  step,
  label,
  labelClassName = "",
  className = "",
  placeholder,
  tooltip = null,
  required = false,
  disabled = false,
}) => {
  return (
    <div>
      <div className={`form-check-${size}`}>
        {/* the field */}
        <input
          {...input}
          id={id ? id : input.name}
          disabled={disabled}
          required={required}
          placeholder={!disabled ? placeholder : null}
          type={type}
          step={step}
          className={
            `form-check-input-${size}` + (className ? ` ${className}` : "")
          }
          data-html="true"
          title={tooltip}
          onFocus={input.onFocus}
          onChange={(e) => {
            // clear HTML5 errors; this is the only place we
            // can clear field-specific server errors
            clearFieldError(form, input.name);

            // if we have a callback, invoke it
            if (input.onChange) {
              input.onChange(e);
            }
          }}
          onBlur={input.onBlur}
          onDragStart={input.onDragStart}
          onDrop={input.onDrop}
        />

        {/* field label */}
        {label != null &&
          ((typeof label === "string" && label.length > 0) || true) && (
            <label
              htmlFor={input.name}
              className={`form-check-label form-check-label-${size} ${
                labelClassName ? labelClassName : ""
              }`}
            >
              <span>{label}</span>
            </label>
          )}
      </div>

      {/* field error */}
      {(touched || disabled) && error && (
        <span className="fsp-field-error">{error}</span>
      )}
    </div>
  );
};

// set prop types and required-ness
Checkbox.propTypes = {
  unwrapped: PropTypes.bool,
  colbreak: PropTypes.string,
  colspan: PropTypes.number,
  size: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  name: PropTypes.string,
  tooltip: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onDragStart: PropTypes.func,
  onDrop: PropTypes.func,
  containerChildren: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
};

// set default props
Checkbox.defaultProps = {
  unwrapped: false,
  colbreak: "sm",
  size: v.formWidgetSize,
  component: renderCheckbox,
  required: false,
  disabled: false,
};

export default Checkbox;
