/**
 * Fetches minimal information about all sites, regardless of entitlement.
 */
export function fetchSitesForSelect(filter = true) {
  // using thunk middleware, so we return a function
  return (dispatch) => {
    // make the call
    return dispatch({
      type: "FETCH_SITES_FOR_SELECT",
      payload: {
        request: {
          method: "get",
          url: `/sites/selectOptions${filter ? "?filter=true" : ""}`,
        },
      },
    });
  };
}
