import React from "react";
import PropTypes from "prop-types";

import { BaseFormComponent } from "..";
import { renderField } from "..";
import * as v from "app/variables";

/*
 * A generic form component that styles consitently with other components.
 * The caller is responsible for supplying the "guts" of the component.
 */
class Generic extends BaseFormComponent {
  render() {
    // parent, for lifecycle logging
    super.render();

    // render
    return (
      <div
        className={
          (this.props.className ? `${this.props.className} ` : "") +
          (this.props.unwrapped
            ? "has-error"
            : (this.props.colspan && this.props.colspan > 0
                ? `col-${this.props.colbreak ? `${this.props.colbreak}-` : ""}${
                    this.props.colspan
                  }`
                : "col") + " has-error")
        }
      >
        {renderField(
          this.props.size,
          null,
          this.props.label,
          this.props.disabled,
          this.props.required,
          null,
          null,
          `col-form-label col-form-label-${this.props.size}`,
          this.props.tooltip,
          this.props.children
        )}
        {this.props.containerChildren}
      </div>
    );
  }
}

// set prop types and required-ness
Generic.propTypes = {
  unwrapped: PropTypes.bool,
  colbreak: PropTypes.string,
  colspan: PropTypes.number,
  size: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  tooltip: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  containerChildren: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
};

// set default props
Generic.defaultProps = {
  unwrapped: false,
  colbreak: "sm",
  size: v.formWidgetSize,
  required: false,
  disabled: false,
};

export default Generic;
