import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { BasePureComponent } from "common/components/Base";
import { isTouchDevice } from "common/util";

/**
 * A base component which all other form components should extend.
 */
export class BaseFormComponent extends BasePureComponent {
  // nothing right now
}

// field wrapped in a tooltip group with a field label and error;
// this needs to live outside the class because callers are not class members
export function renderField(
  size = "lg",
  name,
  label,
  disabled,
  required,
  touched,
  error,
  labelClassName,
  tooltip,
  field,
  onClick = null
) {
  // if possible, we need to split off the last word of the
  // label so we can attach the required icon without wrapping
  let labelA;
  let labelB;
  if (typeof label === "string") {
    if (label && typeof label === "string" && label.length > 0) {
      const split = label.split(" ");
      labelA = split.slice(0, split.length - 1).join(" ");
      labelB = split[split.length - 1];
    }
  }

  return (
    <div>
      {/* field label */}
      {labelB ? (
        <label
          htmlFor={name}
          className={
            (labelClassName ? labelClassName : "") +
            (required && !disabled ? " fsp-required" : " fsp-optional")
          }
        >
          <span>
            {labelA && <>{labelA} </>}
            <span className="end">{labelB}</span>
          </span>
        </label>
      ) : (
        label != null &&
        ((typeof label === "string" && label.length > 0) || true) && (
          <label
            htmlFor={name}
            className={
              (labelClassName ? labelClassName : "") +
              (required && !disabled ? " fsp-required" : " fsp-optional")
            }
          >
            <span>{label}</span>
          </label>
        )
      )}

      {/* tooltip group */}
      <div
        className={
          (tooltip ? `input-group input-group-${size}` : "") +
          `${onClick ? " fsp-clickable" : ""}`
        }
        onClick={onClick}
      >
        {/* the field */}
        {field}

        {/* tooltip (for touch devices) */}
        {tooltip && isTouchDevice() && !disabled && (
          <div className="input-group-append">
            <span className="input-group-text">
              <div className="fsp-tooltip">
                <FontAwesomeIcon icon="question" />
                <span className="fsp-tooltip-text">{tooltip}</span>
              </div>
            </span>
          </div>
        )}
      </div>

      {/* field error */}
      {(touched || disabled) && error && (
        <span className="fsp-field-error">{error}</span>
      )}
    </div>
  );
}
