import moment from "moment/moment";

/**
 * Normalizes a number (phone, zip) by removing all non-digits.
 */
export const normalizeNumber = (number) => {
  // if we don't have anything, return
  if (!number) {
    return number;
  }

  // make sure we can manipulate it
  number = number.toString();

  // strip all non-digits and turn it into a number
  return Number(number.replace(/\D/g, ""));
};

/**
 * Formats a phone number by adding dashes where appropriate.
 */
export const formatPhone = (text) => {
  // if we don't have text, return
  if (typeof text === "undefined" || text === null || text.length === 0) {
    return "";
  }

  // make sure we can manipulate it
  text = text.toString();

  // strip US prefix
  text = text.replace("+1", "");

  // strip non-digits
  text = text.replace(/\D/g, "");

  // if we don't have text any longer, return
  if (text.length === 0) {
    return "";
  }

  // split into parts
  const splitter = /.{1,3}/g;

  // put the parts together with separators
  return text.substring(0, 7).match(splitter).join("-") + text.substring(7);
};

/**
 * Formats a social security number by adding dashes where appropriate.
 */
export const formatSSN = (text) => {
  // if we don't have text, return
  if (typeof text === "undefined" || text === null || text.length === 0) {
    return "";
  }

  // make sure we can manipulate it
  text = text.toString();

  // strip non-digits
  text = text.replace(/\D/g, "");

  // if we don't have text any longer, return
  if (text.length === 0) {
    return "";
  }

  // this is brute force
  switch (text.length) {
    case 1:
    case 2:
    case 3:
      return text;
    case 4:
    case 5:
      return text.substring(0, 3) + "-" + text.substring(3);
    case 6:
    default:
      return (
        text.substring(0, 3) +
        "-" +
        text.substring(3, 5) +
        "-" +
        text.substring(5)
      );
  }
};

/**
 * Formats a zip code.
 */
export const formatZip = (text) => {
  // if we don't have text, return
  if (typeof text === "undefined" || text === null || text.length === 0) {
    return "";
  }

  // make sure we can manipulate it
  text = text.toString();

  // special case; user typed a dash
  const endingDash = text.endsWith("-");

  // strip non-digits
  text = text.replace(/\D/g, "");

  // if we don't have text any longer, return
  if (text.length === 0) {
    return "";
  }

  // split into parts
  const splitter = /.{1,5}/g;

  // put the parts together with separators
  text = text.substring(0, 9).match(splitter).join("-") + text.substring(9);

  // handle ending dash
  if (endingDash && text.length === 5) {
    text += "-";
  }

  return text;
};

/**
 * Formats a date... kind of. Because there are so many permutations of date formats,
 * this method actually only formats a date if the moment library determines that the
 * input is a valid date. Before checking, the input is stripped of non-digits and
 * dashes are replaced with slashes.
 */
export const formatDate = (text, formatDMY) => {
  // if we don't have text, return
  if (typeof text === "undefined" || text === null || text.length === 0) {
    return "";
  }

  // make sure we can manipulate it
  text = text.toString();

  // replace dashes with slashes
  text = text.replace(/-/g, "/");

  // strip non-digits and non-slashes
  text = text.replace(/[^0-9/]/g, "");

  // if we don't have text any longer, return
  if (text.length === 0) {
    return "";
  }

  // see if moment approves of it
  if (moment(text, formatDMY ? "D/M/YYYY" : "M/D/YYYY", true).isValid()) {
    // let moment format it
    return moment(text, formatDMY ? "D/M/YYYY" : "M/D/YYYY", true).format(
      formatDMY ? "DD/MM/YYYY" : "MM/DD/YYYY"
    );
  } else if (moment(text, "YYYY/MM/DD", true).isValid()) {
    // let moment format it
    return moment(text, "YYYY/MM/DD", true).format(
      formatDMY ? "DD/MM/YYYY" : "MM/DD/YYYY"
    );
  } else {
    // leave it alone
    return text;
  }
};

/**
 * Parses a date into YYYY-MM-DD format. If the input cannot be parsed, it is
 * returned unchanged.
 */
export const parseDate = (text, formatDMY) => {
  // see if moment approves of it
  if (moment(text, formatDMY ? "D/M/YYYY" : "M/D/YYYY", true).isValid()) {
    // let moment format it
    return moment(text, formatDMY ? "D/M/YYYY" : "M/D/YYYY", true).format(
      "YYYY-MM-DD"
    );
  } else {
    // leave it alone
    return text;
  }
};

/**
 * Formats a hex string (A-F, 0-9).
 */
export const formatHex = (string) => {
  // if we don't have a string, return
  if (!string) {
    return "";
  }

  // strip non-hex characters
  string = string.replace(/[^0-9A-Fa-f]/g, "");

  // uppercase
  return string.toUpperCase();
};

/**
 * Formats a 24-hour time to 12-hour.
 */
export const formatTime = (string) => {
  // see if moment approves of it
  if (moment(string, "HH:mm", true).isValid()) {
    // let moment format it
    return moment(string, "HH:mm", true).format("h:mm a");
  } else {
    // leave it alone
    return string;
  }
};
