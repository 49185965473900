import React from "react";
import PropTypes from "prop-types";

import { BasePureComponent } from "common/components/Base";
import * as v from "app/variables";

/* A single button. */
class Button extends BasePureComponent {
  render() {
    // parent, for lifecycle logging
    super.render();

    // render
    return (
      <button
        type={this.props.submit ? "submit" : "button"}
        disabled={this.props.disabled}
        className={`mb-1 btn btn-${
          this.props.primary ? "primary" : "secondary"
        } btn-${this.props.size}`}
        title={this.props.title}
        onClick={this.props.onClick}
      >
        {this.props.alternate && (
          <span className="d-block d-sm-none">{this.props.alternate}</span>
        )}
        <span
          className={this.props.alternate ? "d-none d-sm-inline-block" : ""}
        >
          {this.props.children}
        </span>
      </button>
    );
  }
}

// set prop types and required-ness
Button.propTypes = {
  submit: PropTypes.bool,
  primary: PropTypes.bool,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  alternate: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

// set default props
Button.defaultProps = {
  submit: false,
  primary: true,
  disabled: false,
  size: v.formButtonSize,
};

export default Button;
