import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Form, reduxForm, clearSubmitErrors, stopSubmit } from "redux-form";
import { Translate, withLocalize } from "react-localize-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { BasePureComponent } from "common/components/Base";
import Text from "common/components/Form/components/Text";
import Button from "common/components/Form/components/Button";
import {
  verifyPasswordsMatch,
  verifyPasswordFormat,
} from "common/components/Form/utility";
import { processErrors } from "common/components/Form";
import { checkValidity } from "common/components/Form/utility";
import * as errors from "common/util/errors";
import i18n from "./i18n.json";

/* Set a new password. */
class SetPasswordForm extends BasePureComponent {
  constructor(props) {
    // parent
    super(props);

    // load translations
    props.addTranslation(i18n);

    // apply overrides
    if (props.translations) {
      props.addTranslation(props.translations);
    }
  }

  setPassword(values) {
    return this.props.onSubmit(values).catch((e) => {
      // process the error(s)
      processErrors(
        e,
        this.props.translate,
        this.props.dispatch,
        "setPasswordForm.error.generic",
        (error, dispatch) => {
          // handle errors specific to this form
          switch (error.code) {
            // not authenticated
            case errors.NOT_AUTHENTICATED:
              return {
                _error: this.props.translate("setPasswordForm.error.generic"),
              };
            default:
              break;
          }

          return null;
        }
      );
    });
  }

  // password format validator
  passwordFormat = (value) => {
    // defer to utility method
    return verifyPasswordFormat(
      this.props.form,
      "password",
      "setPasswordForm",
      this.props.translate,
      value
    );
  };

  // password match check
  passwordsMatch = (value) => {
    // defer to utility method
    return verifyPasswordsMatch(
      this.props.form,
      "confirmPassword",
      "setPasswordForm",
      this.props.translate,
      "#password",
      value
    );
  };

  render() {
    // parent
    super.render();

    // render
    return (
      <Form
        id={this.props.form}
        onSubmit={this.props.handleSubmit((values) => this.setPassword(values))}
        onChange={() => {
          // check HTML5 validity; this is necessary for user typing, and we do
          // it on a slight delay to account for dynamic fields that may appear
          checkValidity(this);
        }}
        onBlur={() => {
          // check HTML5 validity; this is necessary for browser auto-fills
          checkValidity(this);
        }}
      >
        <div className="card">
          <div className="card-header">
            <Translate id="setPasswordForm.header" />
          </div>
          <div className="card-body">
            {/* errors */}
            {this.props.error && (
              <div className="has-error">
                <div className="fsp-form-error">{this.props.error}</div>
              </div>
            )}
            <div className="form-row">
              <div className="col has-error mt-3">
                <Translate id="setPasswordForm.lead" />
              </div>
            </div>
            <div className="form-row form-group">
              {/* userName */}
              <Text
                colspan={6}
                type={this.props.emailIsUserName ? "email" : null}
                label={this.props.translate(
                  "setPasswordForm.field.userName.label"
                )}
                name="userName"
                autoComplete="username"
                placeholder={this.props.translate(
                  "setPasswordForm.field.userName.label"
                )}
                tooltip={this.props.translate(
                  "setPasswordForm.field.userName.title"
                )}
                minLength={this.props.emailIsUserName ? 5 : 3}
                maxLength={128}
                required
                disabled={this.props.readOnly || this.props.submitting}
              />
            </div>
            <div className="form-row form-group">
              {/* password */}
              <Text
                colspan={6}
                type="password"
                label={this.props.translate(
                  "setPasswordForm.field.password.label"
                )}
                name="password"
                autoComplete="new-password"
                placeholder={this.props.translate(
                  "setPasswordForm.field.password.label"
                )}
                tooltip={this.props.translate(
                  "setPasswordForm.field.password.title"
                )}
                minLength={8}
                maxLength={32}
                validate={[this.passwordFormat, this.passwordsMatch]}
                required
                disabled={this.props.readOnly || this.props.submitting}
                onChange={() => {
                  this.passwordsMatch();
                }}
              />

              {/* confirmPassword */}
              <Text
                colspan={6}
                type="password"
                label={this.props.translate(
                  "setPasswordForm.field.confirmPassword.label"
                )}
                name="confirmPassword"
                autoComplete="new-password"
                placeholder={this.props.translate(
                  "setPasswordForm.field.confirmPassword.label"
                )}
                tooltip={this.props.translate(
                  "setPasswordForm.field.confirmPassword.title"
                )}
                minLength={8}
                maxLength={32}
                validate={this.passwordsMatch}
                required
                disabled={this.props.readOnly || this.props.submitting}
              />
            </div>
          </div>
          <div className="card-footer d-print-none">
            <div className="form-row">
              {/* cancel */}
              <div className="col text-left">
                <Button
                  title={this.props.translate(
                    "setPasswordForm.button.cancel.title"
                  )}
                  onClick={() => this.props.onCancel()}
                  alternate={<FontAwesomeIcon icon="times" />}
                >
                  <Translate id="setPasswordForm.button.cancel.label" />
                </Button>
              </div>

              <div className="col text-right">
                {/* set password */}
                <Button
                  submit
                  disabled={
                    !this.state.htmlValid ||
                    (this.props.invalid && !this.props.submitFailed) ||
                    (!this.props.invalid &&
                      !this.props.submitFailed &&
                      this.props.pristine) ||
                    this.props.submitting
                  }
                  title={this.props.translate(
                    "setPasswordForm.button.setPassword.title"
                  )}
                  alternate={<FontAwesomeIcon icon="check" />}
                >
                  <Translate id="setPasswordForm.button.setPassword.label" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    );
  }
}

// decorate with reduxForm()
SetPasswordForm = reduxForm({
  // clear form-level errors on change
  onChange: (_, dispatch, props) => {
    if (props.error) {
      dispatch(clearSubmitErrors(props.form));
      dispatch(stopSubmit(props.form));
    }
  },
})(SetPasswordForm);

// turn this into a container component
SetPasswordForm = withLocalize(connect()(SetPasswordForm));

// set prop types and required-ness
SetPasswordForm.propTypes = {
  form: PropTypes.string.isRequired,
  emailIsUserName: PropTypes.bool,
  translations: PropTypes.object,
};

// set default props
SetPasswordForm.defaultProps = {
  form: "setPasswordForm",
  emailIsUserName: true,
};

export default SetPasswordForm;
