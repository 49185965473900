import React from "react";
import { connect } from "react-redux";
import { withLocalize } from "react-localize-redux";

import BasePage from "common/components/Page";
import PageHeader from "common/components/PageHeader";
import { BreadcrumbsItem } from "common/components/Breadcrumbs";
import Retry from "common/components/Retry";
import Loading from "common/components/Loading";
import { fetch as getVersion } from "common/entities/Version/actions";
import i18n from "./i18n.json";

/* Info page. */
class Info extends BasePage {
  constructor(props) {
    // parent
    super(props);

    // load translations
    props.addTranslation(i18n);

    // for version management
    this.state = {
      ...this.state,
      version: { hub: "unknown", database: "unknown" },
    };
  }

  componentDidMount() {
    // parent
    super.componentDidMount();

    // load all necessary data
    this.loadData(this.props);
  }

  loadData(props) {
    // run our loaders
    super.loadData(props.loadVersion(), (results) => {
      // the results are in the same order as the calls
      const version = results[0];

      // return the updated state
      return { version: version };
    });
  }

  render() {
    // parent
    super.render();

    // render
    return (
      <div>
        <BreadcrumbsItem
          to={this.props.location.pathname}
          text={this.props.translate("info.header")}
        />
        <PageHeader text={this.props.translate("info.header")} />

        {/* load error? */}
        {super.dataLoadError() && (
          <Retry onRefresh={() => this.loadData(this.props)} />
        )}

        {/* still loading? */}
        {super.dataLoading() && (
          <div className="row">
            <div className="col-12 text-center png-content-loading">
              <Loading className="mt-3 mb-3" />
            </div>
          </div>
        )}

        {/* good? */}
        {super.dataLoadSuccess() && (
          <section id="version">
            {/* version */}
            <div className="row">
              <div className="col fsp-section-header">
                <p>Version</p>
              </div>
            </div>

            <div>
              <div className="row">
                <div className="col">
                  Web Application Version: {process.env.REACT_APP_VERSION}
                </div>
              </div>
              <div className="row">
                <div className="col">Hub Version: {this.state.version.hub}</div>
              </div>
              <div className="row">
                <div className="col">
                  Database Version: {this.state.version.database}
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
    );
  }
}

// map dispatch function to callback props so that the component can invoke them
const mapDispatchToProps = (dispatch) => ({
  // loads the version
  loadVersion: () => {
    // get the version
    return dispatch(getVersion());
  },
});

// turn this into a container component
export default withLocalize(connect(null, mapDispatchToProps)(Info));
