import React from "react";
import PropTypes from "prop-types";
import { withLocalize } from "react-localize-redux";

import { BaseFormComponent } from "..";
import i18n from "./i18n.json";
import Select from "../Select";

/* A specialized select field, used for selecting a state. */
class SelectState extends BaseFormComponent {
  constructor(props) {
    // parent
    super(props);

    // load translations
    props.addTranslation(i18n);
  }

  render() {
    // parent, for lifecycle logging
    super.render();

    // filters according to an optional whitelist
    const filter = (states) => {
      const filtered = {};
      if (this.props.whitelist) {
        for (let key in states) {
          if (this.props.whitelist.includes(key)) {
            filtered[key] = states[key];
          }
        }
        states = filtered;
      }
      return states;
    };

    // render
    return (
      <Select
        colspan={this.props.colspan}
        size={this.props.size}
        label={this.props.label}
        name={this.props.name}
        autoComplete={this.props.autoComplete}
        component={this.props.component}
        placeholder={this.props.placeholder}
        tooltip={this.props.tooltip}
        required={this.props.required}
        disabled={this.props.disabled}
        format={this.props.format}
        normalize={this.props.normalize}
        validate={this.props.validate}
        onFocus={this.props.onFocus}
        onChange={this.props.onChange}
        onBlur={this.props.onBlur}
        onDragStart={this.props.onDragStart}
        onDrop={this.props.onDrop}
        emptyOption={this.props.emptyOption}
        options={filter(i18n.selectState.options)}
        keyPrefix={"selectState.options"}
        translate={this.props.translate}
        children={this.props.children}
        containerChildren={this.props.containerChildren}
      />
    );
  }
}

// localize it
SelectState = withLocalize(SelectState);

// steal most prop types and required-ness from Select
SelectState.propTypes = {
  ...Select.propTypes,
  options: PropTypes.object,
  keyPrefix: PropTypes.string,
  whitelist: PropTypes.array,
};

// steal default props from Select
SelectState.defaultProps = Select.defaultProps;

export default SelectState;
